import Link from "next/link"
import React, {useEffect, useState, useRef} from "react"

const MenuMobile = (props) => {
    useEffect(()=>{
        window.addEventListener("touchstart", handleMenus);
        

        return (
            window.removeEventListener("touchstart", handleMenus)
        )        
    },[])

    const handleSubMenu = async (e) => {
        const menu = e;
        if(document.querySelector("."+menu).classList.contains('show'))
        {
            document.querySelector("."+menu).classList.remove('show');
            document.querySelector("#"+menu).classList.add('fa-caret-down');
            document.querySelector("#"+menu).classList.remove('fa-caret-up');
        }
        else {
            document.querySelector("."+menu).classList.add('show');
            document.querySelector("#"+menu).classList.add('fa-caret-up');
            document.querySelector("#"+menu).classList.remove('fa-caret-down');
        }
    }
    const handleMenus = async () => {   
        document.querySelector("#mainmenuheader").classList.remove('show');             
        props.setshowsearchModal(false);
    }
    const MenuEspacios = props?.menuSections?.find(x => x.id == 4);
    
    return (
	<nav className="navbar tab-mob">
		<button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#mainmenuheader" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation" onClick={() =>handleMenus() }><span className={props.page === 'home-menu' ? 'whitebars' : 'blackbars'}><i className="material-icons menubar">menu</i></span></button>
		<div className="collapse navbar-collapse black-header-div" id="mainmenuheader">
			<div className="search-triangle-black"></div>
			<ul className="navbar-nav mainmenu mr-auto">
				<li className="nav-item active drop-down" onClick={(e) => handleSubMenu('novedadesmenu') } ><span className="nav-link">NOVEDADES<span className="sr-only">(current)</span></span>
					<i className="fa fa-caret-down" aria-hidden="true" id="novedadesmenu"></i>
					<div className="sub-menu novedadesmenu">
						<ul className="navbar-nav">
						<li><Link href="/novedades/esta-semana">A la venta esta semana</Link></li>
						<li><Link href="/comunicados">Comunicados</Link></li>
						</ul>
					</div>
				</li>
				<li className="nav-item cata-wrap drop-down" onClick={(e) => handleSubMenu('catalogomenu') } ><span className="nav-link">CATÁLOGO</span>
					<i className="fa fa-caret-down" aria-hidden="true" id="catalogomenu"></i>
					<div className="sub-menu catalogomenu">
						<ul className="navbar-nav">
						<li onClick={() => {handleMenus()}}><Link href="/sellos/dc-comics" passHref={true}><a>DC Comics</a></Link></li>
						<li onClick={() => {handleMenus()}}><Link href="/sellos/manga"><a>Manga</a></Link></li>
						<li onClick={() => {handleMenus()}}><Link href="/sellos/komodo"><a>Kodomo</a></Link></li>
						<li onClick={() => {handleMenus()}}><Link href="/sellos/ecc-comics"><a>ECC Cómics</a></Link></li>												
						</ul>
					</div>
				</li>
				<li className="nav-item actual-wrap"><Link href="/actualidad">ACTUALIDAD</Link></li>
				<li className="nav-item espac-wrap drop-down" onClick={(e) => handleSubMenu('espaciosmenu') }><span>ESPACIOS</span>
					<i className="fa fa-caret-down" aria-hidden="true" id="espaciosmenu"></i>
					<div className="sub-menu espaciosmenu">
						<ul className="navbar-nav">
                        {MenuEspacios?.childMenu?.filter(x=> x.column == 'Left').sort((a,b) => a.order-b.order).map((menu, i) => {return (<li key={`espacios${i}`}><Link href={`${menu.href}`}><a target={menu.newWindow ==1 ? '_blank' :'_self'} >{`${menu.text}`}</a></Link></li>)})}														                                                              
                        {MenuEspacios?.childMenu?.filter(x=> x.column == 'Right' || x.column == '' ).sort((a,b) => a.order-b.order).map((menu, i) => {return (<li key={`espaciosb${i}`}><Link href={`${menu.href}`}><a target={menu.newWindow ==1 ? '_blank' :'_self'} >{`${menu.text}`}</a></Link></li>)})}    																					
						</ul>
					</div>
				</li>
				<li className="nav-item "><Link href="/merchandise">MERCHANDISE</Link></li>
				<li className="nav-item "><Link href="/agenda">AGENDA</Link></li>
			</ul>
			<div className="small-nav-tab-mob">
				<ul>
					<li className="laEditorial drop-down" onClick={(e) => handleSubMenu('editorialmenu') }><span>La editorial</span>
						<i className="fa fa-caret-down" aria-hidden="true" id="editorialmenu"></i>
						<div className="sub-menu editorialmenu">
							<ul className="navbar-nav">
							<li><Link href="/conocenos">Conócenos</Link></li>
							<li><Link href="/nuestras-tiendas">Nuestras tiendas</Link></li>
							<li><Link href="/nuestras-webs">Nuestras webs</Link></li>
							<li><Link href="/distribucion">Distribución</Link></li>
							</ul>
						</div>
					</li>
					<li><a href="https://librerias.eccediciones.com/" target={"_blank"} rel="noreferrer">Libreros</a></li>
					<li><Link href="/foreign-rights">Foreign rights</Link></li>
					<li><Link href="/ayuda">Ayuda</Link></li>
					<li><Link href="/contacto">Contacto</Link></li>
				</ul>
			</div>
		</div>
	</nav>
    )
}

export default MenuMobile;
