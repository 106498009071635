import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { configStoreData } from "../../../selectors/store";

import Link from "next/link";
import styles from './index.module.css';
import { getHomesearchresults } from '../../../lib/api/myorders';
import EccComicsMenuMobile from "./MenuMobile";


const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

	return [htmlElRef, setFocus]
}

const CommonHeaderEccComics = (props) => {

	const storeId = useRecoilValue(configStoreData);
	console.log('IdStore', storeId);

	const [showsearchModal, setshowsearchModal] = useState(false);
	const [searchData, setsearchData] = useState([]);
	const [isStickyHeader, setStickyHeader] = useState(false);
	const [inputRef, setInputFocus] = UseFocus();
	const show = false;

	useEffect(() => {
		window.addEventListener('scroll', isSticky);
		return () => {
			window.removeEventListener('scroll', isSticky);
		};
	}, [])


	const isSticky = (e) => {
		let resolution = window.innerWidth;
		const scrollTop = window.scrollY;
		if (props?.menu == "white") {
			const header = document.querySelector('.white-header');
			if (scrollTop > 0) {
				header?.classList.add('is-sticky-home');
				setStickyHeader(true);
			}
			else {
				header?.classList.remove('is-sticky-home');
				setStickyHeader(false);
			}

		}
		else {
			const header = document.querySelector('.black-header');
			if (scrollTop > 0) {
				header?.classList.add('is-sticky-home');
				setStickyHeader(true);
			}
			else {
				header?.classList.remove('is-sticky-home');
				setStickyHeader(false);
			}

		}

	};
	const handleMenu = async () => {
		setshowsearchModal(false);
		let resolution = window.innerWidth;
		if (resolution < 1200) {
			if (document.querySelector(".personmenu-tab-mob").classList.contains('showmenu')) {
				document.querySelector(".personmenu-tab-mob").classList.remove('showmenu');
			}
			else {
				document.querySelector("#mainmenuheader").classList.remove('show');
				document.querySelector(".personmenu-tab-mob").classList.add('showmenu');
			}
		}
	}

	const handleAdvanceSearch = async () => {
		if (showsearchModal == true) {
			setshowsearchModal(false);
		}
		else {
			setsearchData("")
			setshowsearchModal(true);
			document.querySelector(".personmenu-tab-mob").classList.remove('showmenu');
			document.querySelector("#mainmenuheader").classList.remove('show');
			setTimeout(() => { setInputFocus(); }, 500);
		}
	}
	const handleCancelAdvancedSearch = () => {
		setshowsearchModal(false)
	}

	const debounce = (cb, delay = 250) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	const updateOptions = debounce(query => {
		getHomesearchresults(query)
			.then(response => setsearchData(response))
	}, 1000)

	const MenuEspacios = props?.menuSections?.find(x => x.id == 4);

	return (
		<>

			<div className={`${styles.HeaderEccComics} black-header ${styles.EccCommonHeader} ${isStickyHeader ? `${styles.stickyHeader} is-sticky-home` : ""}`}>
				<div className="container main-container">
				<div className={styles.upperRightMenu}>
					<div className={`${styles.upperRightMenuWrapper} small-nav`}>
						<ul className={styles.menuUl}>
							<li className="laEditorial"><a className={styles.rightMenuOptions} href="#">La editorial</a>
								<div className="laEditorialMenu">
									<div className="search-triangle-black"></div>
									<ul className="laEditorialUl">
										<li><Link href="/conocenos">Conócenos</Link></li>
										<li><Link href="/nuestras-tiendas">Nuestras tiendas</Link></li>
										<li><Link href="/nuestras-webs">Nuestras webs</Link></li>
										<li><Link href="/distribucion">Distribución</Link></li>
									</ul>
								</div>
							</li>
							<li><Link href="/libreros"><a className={styles.rightMenuOptions}>Libreros</a></Link></li>
							<li><Link href="/foreign-rights"><a className={styles.rightMenuOptions}>Foreign rights</a></Link></li>
							<li><Link href="/ayuda"><a className={styles.rightMenuOptions}>Ayuda</a></Link></li>
							<li><Link href="/contacto"><a className={styles.rightMenuOptions}>Contacto</a></Link></li>
						</ul>
					</div>
					<div className="social-media-wrapper">
						<div className={`${styles.socialMediaIconWrapper} social2`}>
							<ul className="navbar-nav">
								<li className="nav-item"><a rel="noreferrer" target="_blank" href="https://www.facebook.com/ECCEdiciones"><i className={`${styles.socialMediaIcon} fab fa-facebook-square socialico5`} aria-hidden="true"></i></a></li>
								<li className="nav-item"><a rel="noreferrer" target="_blank" href="https://twitter.com/eccediciones"><i className={`${styles.socialMediaIcon} fab fa-x-twitter socialico5`} aria-hidden="true"></i></a></li>
								<li className="nav-item"><a rel="noreferrer" target="_blank" href="https://instagram.com/eccediciones"><i className={`${styles.socialMediaIcon} fab fa-instagram socialico5`} aria-hidden="true"></i></a></li>
								<li className="nav-item"><a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/CanalECCEdiciones"><i className={`${styles.socialMediaIcon} fab fa-youtube socialico5`} aria-hidden="true"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
				</div>
				<div className={styles.stickyHeaderWrapper}>
					<ul className={styles.menuList}>
						<li className={styles.menuItem}><a href="#">NOVEDADES</a></li>
						<li className={styles.menuItem}><a href="#">CATÁLOGO</a></li>
						<li className={styles.menuItem}><a href="#">MERCHANDISING</a></li>
					</ul>
					<div className={styles.stickyHeaderContainer}>
						<div className={styles.stickyHeaderLogo}>
							<img src="/assets/images/bloque-logo-ecc.svg" alt="logo" href="/" />
						</div>
						<div className={styles.stickySearchContainer}>
							<div className={styles.searchwrapper}>
								<input type="text" className={styles.searchBar} />
								<a className={styles.searchButton} href="#"><i className="large material-icons">search</i></a>
							</div>
							<div className={styles.userNav}>
								<ul>
									<li className="nav-item"><a className={styles.navItem}><i className="large material-icons">person</i></a></li>
									<li className="nav-item"><a className={styles.navItem}><i className="large material-icons">shopping_cart</i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid header_wrap">
					<div className={`${styles.headerLogo} ${isStickyHeader ? `${styles.stickyLogo} sticky-logo logo` : ""}`}>
						<img src={!isStickyHeader ? "/assets/images/logo-header-home.png" : "/assets/images/bloque-logo-ecc.svg"} alt="logo" href="/" />
						<EccComicsMenuMobile isStickyHeader={isStickyHeader} />
					</div>
				</div>
			</div>

		</>
	);
};

export default CommonHeaderEccComics;
