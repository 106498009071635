import Link from "next/link"
import React, { useEffect, useState, useRef } from "react"
import styles from './index.module.css';

const EccComicsStickyHeader = () => {

    return (
        <div className={styles.stickyHeaderWrapper}>
            <ul className={styles.menuList}>
                <li className={styles.menuItem}><a href="#">NOVEDADES</a></li>
                <li className={styles.menuItem}><a href="#">CATÁLOGO</a></li>
                <li className={styles.menuItem}><a href="#">MERCHANDISING</a></li>
            </ul>
            <div className={styles.stickyHeaderContainer}>
                <div className={styles.stickyHeaderLogo}>
                    <img src="/assets/images/bloque-logo-ecc.svg" alt="logo" href="/" />
                </div>
                <div className={styles.stickySearchContainer}>
                    <div className={styles.searchwrapper}>
                        <input type="text" className={styles.searchBar} />
                        <a className={styles.searchButton} href="#"><i className="large material-icons">search</i></a>
                    </div>
                    <div className={styles.userNav}>
                        <ul>
                            <li className="nav-item"><a className={styles.navItem}><i className="large material-icons">person</i></a></li>
                            <li className="nav-item"><a className={styles.navItem}><i className="large material-icons">shopping_cart</i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EccComicsStickyHeader;
