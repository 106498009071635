import sendRequest from './sendRequest';

const BASE_PATH = 'api';

export const getOrderList = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/GetMyOrdersList?`+ data, {
        method: 'GET',
    });
export const getOrdersPendingByAddressList = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/GetMyPendingOrdersByAddress`, {
        method: 'POST',
		body: JSON.stringify(data)
    });

export const getOrderDetailsById = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/GetOrderDetails?OrderId=${data}`, {
        method: 'GET',
    });

export const getOrderLines = data =>
    sendRequest(`${BASE_PATH}/OrderLines/myspace/GetOrderLinesList?OrderId=${data}`, {
        method: 'GET',
    });

export const getOrderPaymentandShipmentdetail = data =>
    sendRequest(`${BASE_PATH}/Order/GetPaymentAndShippingDetail/`, {
        method: 'GET',
    });

export const getFavrouites = data =>
    sendRequest(`${BASE_PATH}/customer/myspace/GetMyFavouritesList?${data}`, {
        method: 'GET',
    });

export const removeFavrouites = data =>
    sendRequest(`${BASE_PATH}/customer/myspace/RemoveFromFavourites?Id=${data}`, {
        method: 'DELETE',
    });

export const getScheduleList = data =>
    sendRequest(`${BASE_PATH}/Schedule/GetScheduleList?${data}`, {
        method: 'GET',
    });

export const getHomesearchresults = data =>
    sendRequest(`${BASE_PATH}/Products/GetProductsAdvancedSearch?search=${data ? data : ''}`, {
        method: 'GET',
    });
export const getEventTypes = data =>
    sendRequest(`${BASE_PATH}/Schedule/GetEventsTypesList`, {
        method: 'GET',
    });
export const getOrderInvoice = data =>
    sendRequest(`${BASE_PATH}/Order/myspace/GetOrderInvoice?OrderId=${data}`, {
        method: 'GET',
    });
