import React,{useRef} from 'react';
import useInViewport from '../../lib/hooks/useInViewport';

const HeaderText = ({text, textColor}) => {

    const ref = useRef();
    const inViewport = useInViewport(ref, '0px');

    return(
        <>
          <div className="container-fluid header_wrap">
      			<div className="jumbotron bgheadung">
      				{textColor && <h1 className={`DC-death-metal-prime slide-left ${inViewport? 'animation' : ''}`} ref={ref} style={{color:`${textColor}`}}>{text}</h1>}
                      {!textColor && <h1 className={`DC-death-metal-prime slide-left ${inViewport? 'animation' : ''}`} ref={ref}>{text}</h1>}
      			</div>
      		</div>
        </>
    )
}

export default HeaderText;
