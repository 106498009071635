import { useState, useEffect } from 'react';
import { ordersdata } from "../../selectors/store";
import { userState } from "../../atoms/store";
import { useRecoilValue, useRecoilState } from "recoil";
import Link from "next/link";
import { removeCookies } from 'cookies-next';


const MenuUserZone = ({handleMenu,handleAdvanceSearch, mobile}) => {
    const [cartcount, setcartcount] = useState(0);
    const orders_data = useRecoilValue(ordersdata);
    const [userDataStored, setUserDataStored] = useRecoilState(userState);
    const show=false;

    const closeSession = (e) => {
        e.preventDefault();
        setUserDataStored({});
        removeCookies('eccauthToken');
        removeCookies('user_id');
        window.location.href='/';
    }

    useEffect(() => {
        const _getcartcount = async (orders_data) => {
            let cartcount = 0;
            orders_data?.order?.orderLines?.map((val, i) => {
                  cartcount += val?.quantity;
            })
            setcartcount(cartcount);
        }

      	_getcartcount(orders_data);
  	}, [orders_data])

    return (
        !mobile ?
        <ul className="navbar-nav">
            <li  className="nav-item search-icon"><a onClick={() => handleAdvanceSearch()}><i className="large material-icons box5 forhide">search</i></a></li>
            <li  className="nav-item dropdown personMenuDesktop">

                {userDataStored
                ?
                  <>
                    <Link href="/miespacio/misdatos" passHref={true}><i className="large material-icons box5 forhide">person</i></Link>
                    <div className="user_menu_triangle"></div>
                    <div className="dropdown-menu user_menu" role="menu">
                      <Link  href="#" passHref={true}><a className="dropdown-item useremail">{userDataStored.nombre ? <span>{userDataStored.nombre} {userDataStored.apellidos}</span> : <span>{userDataStored.email} </span>}</a></Link>
                      <Link  href="/miespacio/misdatos" passHref={true}><a className="dropdown-item">MIS DATOS</a></Link>
                      <Link passHref={true} href="/miespacio/mispedidos"><a className="dropdown-item">MIS PEDIDOS</a></Link>
                      <a  href="#" className="dropdown-item logout" onClick={closeSession}><i className="material-icons logouticon">logout</i>CERRAR SESIÓN</a>
                    </div>
                  </>
                :
                  <>
                    <Link href="/auth/login" passHref={true}><i className="large material-icons box5 forhide">person</i></Link>
                    <div className="dropdown-menu login_menu" role="menu">
                        <div className="search-triangle-black"></div>
                        <Link className="dropdown-item" href="/auth/login">Login/Registro</Link>
                    </div>
                  </>

                }
            </li>
            <li  className="nav-item personmenuicon"><a onClick={() => handleMenu()}><i className="large material-icons box5 forhide">person</i></a></li>
            <li  className="nav-item">
            <div className="shopping_cart">
            <Link href="/checkout" passHref={true}><i className="large material-icons forchange p-relative box5">shopping_cart</i></Link>
                {cartcount > 0 ? <span className="cart-count showCart">{cartcount}</span> : <span className="cart-count"></span>}</div></li>
        </ul>
        :
        userDataStored ?
			<>
			<div className="personmenu-triangle-black"></div>
			<div className="row">
				<div className="col-12">
					<div className="list-bar">
						<ul>
								<li className={show == 'misdatos' ?  "active2" : ''}><Link href="/miespacio/misdatos">Mis datos</Link></li>
								<li className={show == 'direction' ?  "active2" : ''}><Link href="/miespacio/misdireccions">Mis direcciones</Link></li>
								<li className={show == 'pedidos' ?  "active2" : ''}><Link href="/miespacio/mispedidos">Mis pedidos</Link></li>
								<li className={show == 'favrouites' ?  "active2" : ''}><Link href="/miespacio/misfavoritos">Mis favoritos</Link></li>
                            <li className={show == 'favrouites' ?  "active2" : ''} ><a onClick={closeSession}>Cerrar sesión</a></li>
						</ul>
					</div>
				</div>
			</div>
			</>
			:
			<>
			<div className="personmenu-triangle-black login"></div>
			<div className="row">
				<div className="col-12">
					<div className="list-bar">
						<ul>
							<li><Link href="/auth/login">Login/Registro</Link></li>
						</ul>
					</div>
				</div>
			</div>
			</>
    )
}

export default MenuUserZone;
