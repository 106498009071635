import React,  {useEffect, useState, useRef} from "react";
import { useRecoilValue } from "recoil";
import { configStoreData } from "../../selectors/store";

import HeaderEccEdiciones from "./HeaderEccEdiciones/index.jsx";
import HeaderEccComics from "./HeaderEccComics/index.jsx";


const Header = (props) => {
	
	const storeId = useRecoilValue(configStoreData);

    return (
    <>
        {storeId == 1 &&
		<HeaderEccEdiciones {...props} />	
		}

		{storeId == 9 &&
		<HeaderEccComics {...props} />	
		}
	</>
  );
};

export default  React.memo(Header);