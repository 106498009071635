import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { configStoreData } from "../../../selectors/store";

import Link from "next/link";
import styles from './index.module.css';

import { getHomesearchresults } from '../../../lib/api/myorders';
import EccComicsMenuMobile from "./MenuMobile";
import CommonHeaderEccComics from "./CommonHeader";
import HomeHeaderEccComics from "./HomeHeader";


const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

	return [htmlElRef, setFocus]
}

const HeaderEccComics = (props) => {

	const storeId = useRecoilValue(configStoreData);
	console.log('IdStore', storeId);

	const [showsearchModal, setshowsearchModal] = useState(false);
	const [searchData, setsearchData] = useState([]);
	const [isStickyHeader, setStickyHeader] = useState(false);
	const [inputRef, setInputFocus] = UseFocus();
	const show = false;

	useEffect(() => {
		window.addEventListener('scroll', isSticky);
		return () => {
			window.removeEventListener('scroll', isSticky);
		};
	}, [])

	const isSticky = (e) => {
		let resolution = window.innerWidth;
		const scrollTop = window.scrollY;
		if (props?.menu == "white") {
			const header = document.querySelector('.white-header');
			if (scrollTop > 0) {
				header?.classList.add('is-sticky-home');
				setStickyHeader(true);
			}
			else {
				header?.classList.remove('is-sticky-home');
				setStickyHeader(false);
			}

		}
		else {
			const header = document.querySelector('.black-header');
			if (scrollTop > 0) {
				header?.classList.add('is-sticky-home');
				setStickyHeader(true);
			}
			else {
				header?.classList.remove('is-sticky-home');
				setStickyHeader(false);
			}

		}

	};
	const handleMenu = async () => {
		setshowsearchModal(false);
		let resolution = window.innerWidth;
		if (resolution < 1200) {
			if (document.querySelector(".personmenu-tab-mob").classList.contains('showmenu')) {
				document.querySelector(".personmenu-tab-mob").classList.remove('showmenu');
			}
			else {
				document.querySelector("#mainmenuheader").classList.remove('show');
				document.querySelector(".personmenu-tab-mob").classList.add('showmenu');
			}
		}
	}

	const handleAdvanceSearch = async () => {
		if (showsearchModal == true) {
			setshowsearchModal(false);
		}
		else {
			setsearchData("")
			setshowsearchModal(true);
			document.querySelector(".personmenu-tab-mob").classList.remove('showmenu');
			document.querySelector("#mainmenuheader").classList.remove('show');
			setTimeout(() => { setInputFocus(); }, 500);
		}
	}
	const handleCancelAdvancedSearch = () => {
		setshowsearchModal(false)
	}

	const debounce = (cb, delay = 250) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	const updateOptions = debounce(query => {
		getHomesearchresults(query)
			.then(response => setsearchData(response))
	}, 1000)

	const MenuEspacios = props?.menuSections?.find(x => x.id == 4);

	return (
		<>
			{
				props.page === "home"
				&&
				(
					<HomeHeaderEccComics props={props} />
				)
			}
			{
				props.page !== "home"
				&&
				(
					<CommonHeaderEccComics props={props} />
			)
			}


		</>
	);
};

export default HeaderEccComics;
