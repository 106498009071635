import Link from "next/link"
import React, { useEffect, useState, useRef } from "react"
import styles from './MenuMobile.module.css';

const EccComicsMenuMobile = ({isStickyHeader}) => {
	useEffect(() => {
		window.addEventListener("touchstart", handleMenus);


		return (
			window.removeEventListener("touchstart", handleMenus)
		)
	}, [])
	const handleMenus = async () => {
		document.querySelector("#mainmenuheader").classList.remove('show');
	}
	return (
		<div className={`${styles.EccComicsMenuMobileWrapper} ${isStickyHeader ? styles.stickyMobileHeader:""}`}>
			<nav className={`navbar tab-mob ${styles.EccComicsMenuMobile}`}>
				<button className={`navbar-toggler collapsed ${styles.navBarToggler}`} type="button" data-toggle="collapse" data-target="#mainmenuheader" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation" onClick={() => handleMenus()}><span className={`${!isStickyHeader?'whitebars':'blackbars'}`}><i className="material-icons menubar">menu</i></span></button>
				<div>
					<div className={`collapse navbar-collapse black-header-div ${styles.navBarCollapse}`} id="mainmenuheader">
						<div className="search-triangle-black"></div>
						<ul className={styles.menuList}>
							<li className={styles.menuItem}><a href="#">NOVEDADES</a></li>
							<li className={styles.menuItem}><a href="#">CATÁLOGO</a></li>
							<li className={styles.menuItem}><a href="#">MERCHANDISING</a></li>
						</ul>
					</div>
				</div>
			</nav>
			<div className={styles.userNav}>
				<ul>
					{
						isStickyHeader &&
						<li className="nav-item"><a className={styles.navItem}><i className="large material-icons">search</i></a></li>
					}
					<li className="nav-item"><a className={styles.navItem}><i className="large material-icons">person</i></a></li>
					<li className="nav-item"><a className={styles.navItem}><i className="large material-icons">shopping_cart</i></a></li>
				</ul>
			</div>
		</div>
	)
}

export default EccComicsMenuMobile;
