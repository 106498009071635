import { useState, useEffect } from 'react'

const useInViewport = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const currentElement = element.current
        const observer = new IntersectionObserver(
            ([entry]) => {
              
                setState(entry.isIntersecting);
            }, { rootMargin }
        );

        currentElement && observer.observe(currentElement);

        return () => {
            if(currentElement){
                observer.unobserve(currentElement);
            }

        };
    }, []);

    return isVisible;
};

export default useInViewport;
